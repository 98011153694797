// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-activiteiten-js": () => import("/opt/build/repo/src/pages/activiteiten.js" /* webpackChunkName: "component---src-pages-activiteiten-js" */),
  "component---src-pages-asbest-js": () => import("/opt/build/repo/src/pages/asbest.js" /* webpackChunkName: "component---src-pages-asbest-js" */),
  "component---src-pages-contact-js": () => import("/opt/build/repo/src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-nr-1-in-camaliteiten-js": () => import("/opt/build/repo/src/pages/nr-1-in-camaliteiten.js" /* webpackChunkName: "component---src-pages-nr-1-in-camaliteiten-js" */),
  "component---src-pages-over-ons-js": () => import("/opt/build/repo/src/pages/over-ons.js" /* webpackChunkName: "component---src-pages-over-ons-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/opt/build/repo/.cache/data.json")

